import React from 'react';
import PropTypes from 'prop-types';
import 'fontsource-source-sans-pro';
import { Helmet } from 'react-helmet';
import { Icon } from '@iconify/react';
import arrowIcon from '@iconify/icons-fa-solid/arrow-circle-up';
import { Link } from 'gatsby';

import Header from './header';
import layoutStyles from './layout.module.scss';

const Layout = ({ children }) => {
    return (
        <div>
            <Helmet title="Wai Yar - Portfolio" />
            <Header />
            <div>
                <main>{children}</main>
                <footer>
                    <Link to="#top" className={layoutStyles.link}>
                        <Icon icon={arrowIcon} />
                    </Link>
                </footer>
            </div>
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
