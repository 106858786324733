import { Link } from 'gatsby';
import React from 'react';
import { Icon } from '@iconify/react';
import githubIcon from '@iconify-icons/fa-brands/github';

import headerStyles from './header.module.scss';

const Header = () => (
    <header id="top">
        <Link to="/" className={headerStyles.logo}>
            Wai Yar Aung
        </Link>
        <nav className={headerStyles.nav}>
            <ul className={headerStyles.ul}>
                <li>
                    <Link to="#work_section" className={headerStyles.link}>
                        Work
                    </Link>
                </li>
                <li>
                    <Link to="https://github.com/waiyar" className={headerStyles.link}>
                        <Icon icon={githubIcon} className={headerStyles.icon} />
                    </Link>
                </li>
                {/* <li>
                    <Link to="#project_section" className={headerStyles.link}>
                        Projects
                    </Link>
                </li>
                <li>
                    <Link to="/contact/" className={headerStyles.link}>
                        Contact
                    </Link>
                </li> */}
            </ul>
        </nav>
    </header>
);

export default Header;
